import { InvigilatorService } from "Services/invigilatorService";
import { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap"
import { toast } from "react-toastify";

interface IShowPasswordModal {
    show: any,
    handleClose: any,
}

export default function ShowPasswordModal(props: IShowPasswordModal) {



    return (
        <>
            <Offcanvas show={props.show ? true : false} onHide={() => props.handleClose()} placement="end" animation={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Password</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                   <div>
                          <h6>New Password is: {props.show}</h6>
                   </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}