import { InvigilatorService } from "Services/invigilatorService";
import { useEffect, useState } from "react";
import { Badge, Button, Form, Offcanvas } from "react-bootstrap"
import { toast } from "react-toastify";
import Select from "react-select";
import { ExamServices } from "Services/examServices";

interface IAddInvigilator {
    id: string,
    setId: any,
    reload: any
}

export default function Invigilator({ id, setId, reload }: IAddInvigilator) {

    const [exams, setExams] = useState([]);
    const [examStudents, setExamStudents] = useState([]);
    const [selectedExam, setSelectedExam] = useState<any>(undefined);
    const [selectedStudents, setSelectedStudents] = useState<any>([]);


    async function getAllExams() {
        await ExamServices.getAllExams()
            .then((res) => {
                if (res.status === 200) {
                    res.data.data?.examData &&
                        res.data.data.examData.length > 0 &&
                        setExams(res.data.data.examData.map((item: any) => {
                            return {
                                value: item._id,
                                label: item.examName
                            }
                        }))
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    async function getExamStudents() {
        await ExamServices.getAllUsers(selectedExam.value)
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res.data.data.users);
                    res.data.data?.users &&
                        res.data.data.users.length > 0 &&
                        setExamStudents(res.data.data.users.map((item: any) => {
                            return {
                                value: item.student?._id,
                                label: item.student?.name + ' ' + item.student?.last_name + ' (' + item.student?.username + ' )'
                            }
                        }))
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    async function handleSave() {
        if (!selectedExam || !selectedExam.value) {
            toast.error('Please select exam')
            return;
        }
        if (!selectedStudents || selectedStudents.length === 0) {
            toast.error('Please select students')
            return;
        }
        let payload = {
            examId: selectedExam.value,
            students: selectedStudents.map((item: any) => item.value)
        }
        await InvigilatorService.allotExam(id, payload)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Access updated successfully');
                    reload();
                    onModalClose();
                }
            })
            .catch(e => {
                console.log(e);
                toast.error(e.response?.data?.message || e.response?.data );
            })
    }

    function onModalClose() {
        setSelectedExam(undefined);
        setSelectedStudents([]);
        setExamStudents([]);
        setExams([]);
        setId('');
    }

    useEffect(() => {
        if (id !== '') {
            getAllExams()
        }
    }, [id]);

    useEffect(() => {
        if (selectedExam && selectedExam.value) {
            getExamStudents()
        }
    }, [selectedExam]);


    return (
        <>
            <Offcanvas show={id !== ''} onHide={onModalClose} placement="end" animation={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Manage Access</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="">
                            <Form.Group className="mb-3">
                                <Form.Label>Exams</Form.Label>
                                <Select
                                    options={exams}
                                    onChange={e => setSelectedExam(e)}
                                    value={selectedExam}
                                    isClearable
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Students</Form.Label>
                                <Select
                                    options={examStudents}
                                    value={selectedStudents}
                                    onChange={e => setSelectedStudents(e)}
                                    isMulti
                                />
                                {
                                    examStudents.length > 0 &&
                                    <div className="text-end mt-1" style={{ cursor: 'pointer' }}>
                                        <Badge pill bg="secondary" onClick={() => setSelectedStudents(examStudents)} >Select All</Badge>
                                    </div>
                                }
                            </Form.Group>
                        </div>
                        <Button variant="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}