import React, { useState, useEffect } from 'react';
import './DwvComponent.css';



import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';

// import TagsTable from './TagsTable';

import './DwvComponent.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
const dwv = require('dwv');





// Image decoders (for web workers)

dwv.image.decoderScripts = {
  jpeg2000: `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`,
  "jpeg-lossless": `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`,
  "jpeg-baseline": `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`,
  rle: `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`,
};


var filterList = ['Threshold', 'Sharpen', 'Sobel'];

export const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

export function DcmViewerHook(props) {

  const [tools, setTools] = useState({
    Scroll: {},
    ZoomAndPan: {},
    WindowLevel: {},
    // "gui": ["tool"],
    // Filter: {
    //   options: filterList,
    //   type: 'instance',
    //   events: ['filterrun', 'filterundo']
    // },

    // Draw: {
    //   options: ['Ruler', 'Arrow', 'Rectangle', 'Circle'],
    //   type: 'factory',
    //   events: ['drawcreate', 'drawchange', 'drawmove', 'drawdelete']
    // },
    // Opacity: {}

  })
  const [toolNames, settoolNames] = useState([])
  const [selectedTool, setselectedTool] = useState("Select Tool")
  const [loadProgress, setloadProgress] = useState(0)
  const [dataLoaded, setdataLoaded] = useState(false)
  const [dwvApp, setdwvApp] = useState(null)
  const [metaData, setmetaData] = useState([])
  const [toolMenuAnchorEl, settoolMenuAnchorEl] = useState(null)
  const [isLoaded, setDicomIsLoaded] = useState(false)
  const [scroll, setScroll] = useState(0);
  const [windowLevel, setWindowLevel] = useState([]);


  console.log(metaData)
  const [reload, setReload] = useState(false);

  const [versions, setVersions] = useState({
    dwv: dwv.getVersion(),
    react: React.version
  })


  const onChangeTool = (tool) => {
    if (dwvApp) {
      setselectedTool(tool);
      dwvApp.setTool(tool);
      if (tool === 'Draw') {
        onChangeShape(tools.Draw.options[0]);
      }
    }

  }


  /**
   * Handle a change draw shape event.
   * @param shape The new shape name.
   */
  const onChangeShape = (shape) => {
    if (dwvApp) {
      dwvApp.setDrawShape(shape);
    }
  }

  /**
   * Handle a reset event.
   */
  const onReset = tool => {
    if (dwvApp) {
      dwvApp.resetDisplay();
      dwvApp.resetZoom();
    }
  }



  /**
   * Menu item click.
   */
  const handleMenuItemClick = tool => {
    settoolMenuAnchorEl(null);
    onChangeTool(tool);
  };




  useEffect(() => {
    // create app
    var app = new dwv.App();
    // initialise app
    app.init({
      "dataViewConfigs": { '*': [{ divId: props.controlId }] },
      "tools": tools
    });

    // load events
    let nLoadItem = null;
    let nReceivedError = null;
    let nReceivedAbort = null;
    let isFirstRender = null;

    app.addEventListener('loadstart', (/*event*/) => {
      // reset flags
      nLoadItem = 0;
      nReceivedError = 0;
      nReceivedAbort = 0;
      isFirstRender = true;
    });

    app.addEventListener("loadprogress", (event) => {
      setloadProgress(event.loaded);
    });

    app.addEventListener('renderend', (event) => {
      if (isFirstRender) {
        isFirstRender = false;
        // available tools
        let names = [];
        for (const key in tools) {
          if ((key === 'Scroll' && app.canScroll()) ||
            (key === 'WindowLevel' && app.canWindowLevel()) ||
            (key !== 'Scroll' && key !== 'WindowLevel')) {
            names.push(key);
          }
        }

        console.log(app.canScroll())
        settoolNames(names);
        onChangeTool(names[0]);
      }
    });

    app.addEventListener("load", (/*event*/) => {
      setmetaData(dwv.utils.objectToArray(app.getMetaData(0)))
      setdataLoaded(true)
    });
    app.addEventListener('loadend', (/*event*/) => {
      if (nReceivedError) {
        setloadProgress(0);
      }
      if (nReceivedAbort) {
        setloadProgress(0);
        alert('Load was aborted.');
        // showDropbox(app, true);
      }
    });
    app.addEventListener('loaditem', (event) => {
      ++nLoadItem;
    });
    app.addEventListener('error', (event) => {
      console.error(event.error);
      ++nReceivedError;
    });
    app.addEventListener('abort', (/*event*/) => {
      ++nReceivedAbort;
    });

    app.addEventListener('positionchange', (event) => {
      const layerGroup = app.getActiveLayerGroup();
      if (layerGroup) {
        var viewController =
          layerGroup.getActiveViewLayer().getViewController();
        setScroll(viewController.getCurrentScrollIndexValue() +1);
      }
    })

    app.addEventListener('wlchange', (event) => {
      setWindowLevel(event.value)
    });

    // handle key events
    app.addEventListener('keydown', (event) => {
      app.defaultOnKeydown(event);
    });

    // handle window resize
    window.addEventListener('resize', app.onResize);
    setdwvApp(app);

    // setup drop box
    handleDataUnzip()


  }, [reload])


  useEffect(() => {
    handleDataUnzip()
  }, [props.dicomData, dwvApp])

  const handleDataUnzip = async () => {
    if (dwvApp && props.dicomData) {
      dwvApp.loadImageObject(props.dicomData);
      setDicomIsLoaded(true)
    }
  }





  return (
    <div id="dwv">
      <LinearProgress variant="determinate" value={loadProgress} />
      <div className="d-flex justify-content-start align-items-center">
        <div className="fw-bold me-4 ms-4 dcm-link-cursor bg-white px-3 py-2 text-danger border border-1 border-danger" onClick={() => props.onClose()}>
          <FontAwesomeIcon icon={faClose} className="me-1" />  Close View
        </div>
        <div style={{ minWidth: "20rem" }}>
          <label style={{
            fontWeight: 'bold',
            lineHeight: 2,
            color: "#fff"
          }}>
            Select Tool
            {selectedTool === "Scroll" &&
              <span className="text-warning ms-3 ">
                {"(" + scroll + " / " + props.dicomData.length + ")"}
              </span>}
              {selectedTool === "WindowLevel" &&
              <span className="text-warning ms-3 ">
                {"( wc: " + windowLevel[0] + ", ww: " + windowLevel[1] + ")"}
              </span>}
          </label>
          {toolNames && toolNames.length > 0 &&
            <Select
              onChange={(tool) => handleMenuItemClick(tool.value)}
              options={toolNames.map((data) => {
                return {
                  value: data,
                  label: data
                }
              })}
            />
          }
        </div>



        {selectedTool === 'Draw' &&
          <div style={{ minWidth: "20rem" }} className="ms-2">
            <label style={{
              fontWeight: 'bold',
              lineHeight: 2,
              color: "#fff"
            }}>
              Select Shape
            </label>
            <Select
              onChange={(e) => onChangeShape(e.value)}
              options={tools.Draw.options.map((data) => {
                return {
                  value: data,
                  label: data
                }
              })}
            />
          </div>

        }


        <div className='ms-3'>

          <div style={{
            fontWeight: 'bold',
            lineHeight: 2,
            color: "#000"
          }}>
            Reset
          </div>
          <div>
            <Button variant="contained" color="primary"
              disabled={!dataLoaded}
              onClick={onReset}
            >Reset Image</Button>
          </div>

        </div>

        {/* 
        <div className='ms-3'>

          <div style={{
            fontWeight: 'bold',
            lineHeight: 2,
            color: "#000"
          }}>
            Reload Dicom
          </div>
          <div>
            <Button variant="contained" color="primary"
              disabled={!dataLoaded}
              onClick={onReload}
            >Reload Dicom</Button>
          </div>

        </div> */}

      </div>

      <div id={props.controlId} className="layerGroup">
        {/* <div id="dropBox"></div> */}
      </div>
    </div>
  );
}