import { InvigilatorService } from "Services/invigilatorService";
import { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap"
import { toast } from "react-toastify";

interface IEditInvigilator {
    show: any,
    handleClose: any,
    reload: any,
    invigilatorData: any
}

export default function EditInvigilator({ show, handleClose, reload, invigilatorData }: IEditInvigilator) {

    const [data, setData] = useState<any>();

    const handleChangeValue = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const updateInvigilator = async () => {

        const payload = {
            name: data.name,
            mobile: data.mobile,
        }

        await InvigilatorService.updateInvigilator(data._id, payload).then((res) => {
            if (res.status === 200) {
                toast.success("Invigilator Created");
                handleClose();
                reload();
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }


    useEffect(() => {
        setData(invigilatorData)
    }, [show])

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement="end" animation={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Invilator</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ height: "84vh" }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                name="email"
                                defaultValue={data?.email}
                                onChange={(e: any) => handleChangeValue(e)}
                                disabled={true}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                defaultValue={data?.name}
                                onChange={(e: any) => handleChangeValue(e)}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                                name="mobile"
                                defaultValue={data?.mobile}
                                onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                    </div>
                    <div>
                        <Button variant="secondary" className="w-100" onClick={updateInvigilator}>Save</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}