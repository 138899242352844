import { faEdit, faPencil, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Toolbar from "../../../../components/Toolbar/Toolbar";
import { ExamServices } from "../../../../Services/examServices";
import { AdminService } from "Services/adminService";

interface IExamStatus {
  data: any;
  reloadData: any
}



const ExamStatus = ({ data, reloadData }: IExamStatus) => {

  const [userData, setUserData] = useState<any>();



  const updateExamStatus = async (e: any) => {
    await AdminService.toggleExam(data._id, { userShow: e }).then((res) => {
      if (res.status === 200) {
        toast.success("Status updated successfully.");
        reloadData();
      }
    }).catch(err => {
      toast.error("Something went wrong.");
    })
  };

  useEffect(() => {

    setUserData({
      userShow: data.userShow,
    });
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-start">
        <Form.Group
          className="d-flex aling-items-center"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>User Show</Form.Label>
          <Form.Switch
            name="userShow"
            className="me-4 ms-1"
            style={{
              textAlign: "center",
            }}
            checked={userData?.userShow === true}
            defaultValue={userData?.userShow}
            onChange={(e) => updateExamStatus(e.target.checked)}
          />
        </Form.Group>
      </div>
    </>
  );
};

export default ExamStatus;
