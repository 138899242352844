import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

interface IStatisticsCard {
    icon: any,
    title: any,
    value: any
}

export default function StatsCard(props: IStatisticsCard) {
    return (
        <>
            <Card className="shadow-sm" style={{ width: "18.7rem", border: "none" }}>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-flex flex-column align-items-start gap-4">
                                <FontAwesomeIcon icon={props.icon} className="fs-3" />
                                <p className="" style={{ fontWeight: "500", fontSize: 18 }}>{props.title}</p>
                            </div>
                        </div>
                        <div>
                            <p className="fw-bold fs-4">{props.value}</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}