/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Badge, Button, Form, Tab, Table, Tabs } from 'react-bootstrap';
import moment from 'moment';
import { ExamServices } from '../../../../Services/examServices';
import { toast } from 'react-toastify';
import ExamStatus from './ExamStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faCheck,
  faCheckCircle,
  faCircleDot,
  faCircleQuestion,
  faClock,
  faDownload,
  faFileExport,
  faFilter,
  faGear,
  faPenAlt,
  faPencil,
  faRefresh,
  faReplyAll,
  faSquarePollVertical,
  faStamp,
  faTimesCircle,
  faTrash,
  faUpload,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import UploadCandidateData from '../../../../components/Modals/UploadCandidateData.modal';
import CardBasic from '../../../../components/Cards/Card.basic';
import ExamUserTable from './ExamUserTable';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PromptModal from '../../../../components/Modals/Prompt.modal';
import './ExamTime.css';
import { CSVDownload, CSVLink } from 'react-csv';
import TimeModal from './TimeModal';
import { AdminService } from 'Services/adminService';
import UnmappedUsersTable from 'components/Tables/UnmappedUsersTable';
import TablePagination from 'components/Pagination/Table.paginaition';
import FilterModal from 'components/Modals/Filter.modal';
interface ISelectedExam {
  examData: any;
  reloadData: any;
}

interface IInitialExamStatus {
  startAllowed: any;
  downloadData: any;
  userShow: any;
}

const TimeType = {
  examTime: {
    label: 'Exam Enabled(Reporting) and Disable(Maximum allowed Till) Time',
    type: 'examTime',
  },
  userAllowedTime: {
    label: 'Exam Duration Time',
    type: 'userAllowedTime',
  },
  userTillTime: {
    label: 'Gate Closing Time',
    type: 'userTillTime',
  },
};

export default function SelectedExamCard({
  examData,
  reloadData,
}: ISelectedExam) {
  const [examStatus, setExamStatus] = useState<IInitialExamStatus | any>({
    startAllowed: '',
    downloadData: '',
    userShow: '',
  });

  const params: any = useParams();
  const navigate = useNavigate();

  const [showCandidateDataModal, setShowCandidateDataModel] =
    useState<boolean>(false);
  const [confirmPrompt, setConfirmPrompt] = useState<boolean>(false);

  const [userData, setUserData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [userAllowedExamTime, setUserAllowedExamTime] = useState({
    userAllowedStartTime: '',
    userAllowedEndTime: '',
  });

  const [examTimeModal, setExamTimeModal] = useState<boolean>(false);
  const [date_type, setDate_type] = useState<{
    type: string;
    label: string;
  }>();

  const [examTime, setExamTime] = useState<{
    startTime: string;
    endTime: string;
  }>();
  const [userAllowedTime, setUserAllowedTime] = useState<{
    startTime: string;
    endTime: string;
  }>();
  const [userTillTime, setUserTillTime] = useState('');


  const [key, setKey] = useState<any>('All');
  const [unmappedUsers, setUnmappedUsers] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<any>("");

  const [filterData, setFilterData] = useState<any>();
  const [csvData, setCsvData] = useState<any>([]);


  const getExamInvigilatorStats = async () => {
    await ExamServices.getExamInvigilatorStats(params.examId).then((res) => {
      if (res.status === 200) {
        setCsvData(res.data.invigilatorsData.map((data: any) => {
          return {
            "Email": data?.invigilator?.email,
            "Name": data?.invigilator?.name,
            "Status": data?.invigilator?.status,
            "Total Exam Mapping": data?.totalExamMappings,
            "Remaining Exam Mapping": data?.totalExamMappings - data?.examMappingsWithCompletionStatus,
            "Completion Status": data?.examMappingsWithCompletionStatus,
            "Invigilator Submitted": data?.examMappingsWithInvigilatorSubmitted,
            "Student Submitted": data?.examMappingsWithStudentSubmitted,
            "Alloted Exam": data?.invigilator?.allottedExams?.length,
          }
        }))
      }
    })
  }

  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY hh:mm a');
  };

  // const examStatusData = async () => {
  //   await ExamServices.getExamStatus(params.examId)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setExamStatus(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.response);
  //     });
  // };

  const getAllUnmappedUser = async () => {
    await AdminService.getAllUnmappedUser(params.examId, searchText, perPage, currentPage).then((res) => {
      if (res.status === 200) {
        setUnmappedUsers(res.data.unmapped)
        setTotalCount(res.data.total)
      }
    })
  }

  const getAllMappedUser = async () => {

    await ExamServices.getAllUsers(params.examId, searchText, filterData?.completionStatus, filterData?.invigilatorSubmitForReview, filterData?.studentSubmitForReview, filterData?.isGenerated, filterData?.notGenerated, filterData?.readyToBeGenerated).then((res) => {
      setUserData(res.data.data.users);
      setTotalPages(1)
    }).catch((err) => {
      toast.error('Unable to fetch User data');
    });
  };

  const makeExamLive = async () => {
    await ExamServices.makeExamLive(params.examId).then((res) => {
      if (res.status === 200) {
        toast.success('Exam is Live');
        reloadData();
      }
    });
  };


  const makeExamNotLive = async () => {
    await ExamServices.updateExamStatus(
      {
        startAllowed: false,
        downloadData: false,
        userShow: false,
      },
      params.examId,
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success('Exam is not live');
          setExamStatus(!examStatus);
        }
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };

  const handleDeleteExamMapping = async () => {
    await ExamServices.deleteMappingForExam(params.examId).then((res) => {
      if (res.status === 200) {
        toast.success('Clear Mapping');
        reloadData();
        setConfirmPrompt(false);
      }
    });
  };

  const markExamCompleted = async () => {
    await ExamServices.markExamCompleted(params.examId).then((res) => {
      if (res.status === 200) {
        toast.success('Exam Status Changed');
        reloadData();
      }
    });
  };

  // const getUserAllowedExamTime = async () => {
  //   await ExamServices.getUserAllowedExamTime(params.examId)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setUserAllowedExamTime(res.data.data);
  //       }
  //     })
  //     .catch((err) => { });
  // };

  const handleTimeExamTime = async () => {
    await ExamServices.setExamDateAndTime(
      params.examId,
      examTime?.startTime ?? examData.startTime,
      examTime?.endTime ?? examData.endTime,
    ).catch((err) => console.log(err));
    setExamTimeModal(false);
    reloadData()
  };

  const handleUserTillTime = async () => {
    await ExamServices.setUserAllowedTillTime(
      params.examId,
      userTillTime ?? examData.userAllowedTillTime,
    ).catch((err) => console.error(err, true));
    setExamTimeModal(false);
    reloadData()
  };



  const handleUserAllowedTime = async () => {
    await ExamServices.setUserAllowedTime(
      params.examId,
      userAllowedTime?.startTime ?? userAllowedExamTime.userAllowedStartTime,
      userAllowedTime?.endTime ?? userAllowedExamTime.userAllowedEndTime,
    ).then(() => setExamTimeModal(false));
    reloadData()
  };

  const defaultTime =
    date_type === TimeType.examTime
      ? {
        start: examData.startTime,
        end: examData.endTime,
      }
      : date_type === TimeType.userAllowedTime
        ? {
          start: userAllowedExamTime.userAllowedStartTime,
          end: userAllowedExamTime.userAllowedEndTime,
        }
        : date_type === TimeType.userTillTime
          ? {
            start: examData.userAllowedTillTime,
          }
          : {};

  useEffect(() => {
    getExamInvigilatorStats()
  }, [])


  useEffect(() => {
    getAllUnmappedUser();
  }, [params.examId, searchText, pageCount, currentPage, perPage])

  useEffect(() => {
    getAllMappedUser();
  }, [pageNumber, pageCount, examData, showCandidateDataModal, searchText]);

  return (
    <>
      <PromptModal
        setShow={setConfirmPrompt}
        show={confirmPrompt}
        body={
          <>
            <div>
              <p>Are you sure you want to Delete All Users?</p>
            </div>
          </>
        }
        submitButton={
          <Button
            variant="danger"
            className="ms-2"
            onClick={handleDeleteExamMapping}
          >
            Delete Users <FontAwesomeIcon icon={faTrash} />
          </Button>
        }
      />
      {examTimeModal && (
        <TimeModal
          show={examTimeModal}
          setShow={setExamTimeModal}
          type={date_type}
          onTimeChange={(start, end) => {
            const ISOStart = start;
            const ISOEnd = end;
            if (date_type === TimeType.examTime) {
              setExamTime({ startTime: ISOStart, endTime: ISOEnd });
              return;
            }
            if (date_type === TimeType.userAllowedTime) {
              setUserAllowedTime({ startTime: ISOStart, endTime: ISOEnd });
              return;
            }
            if (date_type === TimeType.userTillTime) {
              setUserTillTime(ISOStart);
              return;
            }
          }}
          onSubmit={() => {
            if (date_type === TimeType.examTime) {
              handleTimeExamTime();
              return;
            }
            if (date_type === TimeType.userAllowedTime) {
              handleUserAllowedTime();
              return;
            }
            if (date_type === TimeType.userTillTime) {
              handleUserTillTime();
              return;
            }
          }}
          defaultValues={defaultTime}
        />
      )}
      <CardBasic
        cardClass="mt-2"
        body={
          <>
            <div className="d-flex justify-content-end"></div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <div className="d-flex justify-content-start align-items-center">
                  <span>
                    Name: <b>{examData?.examName}</b>
                  </span>
                </div>
                <div>
                  <span>
                    Subject: <b>{examData?.subject}</b>
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span>
                    Created at <b>{formatDate(examData.createdAt)}</b>
                  </span>
                </div>

                <div>
                  <span>
                    Last updated at <b>{formatDate(examData.updatedAt)}</b>
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              {examData.tags
                ? examData.tags.map((tag: any) => {
                  return (
                    <>
                      <Badge bg="secondary" className="me-2">
                        {tag}
                      </Badge>
                    </>
                  );
                })
                : ''}
            </div>
            <div>
              <span>
                Status:{' '}
                <b>{examData.status == 'live' && <span className="text-danger">LIVE <FontAwesomeIcon icon={faCircleDot} className="ms-1" /></span>}</b>
                <b>{examData.status == 'completed' && <span className="text-success">COMPLETED <FontAwesomeIcon icon={faCircleDot} className="ms-1" /></span>}</b>
                <b>{examData.status == 'upcoming' && <span className="text-info">UPCOMING <FontAwesomeIcon icon={faCircleDot} className="ms-1" /></span>}</b>
              </span>
            </div>
            <div className="mt-1">
              <ExamStatus data={examData} reloadData={reloadData} />
            </div>
          </>

        }
      />

      <CardBasic
        cardClass="mt-3"
        body={
          <div className="d-flex justify-content-between align-items-center">


            <Toolbar
              values={[
                {
                  value: (
                    <>
                      {examStatus.startAllowed &&
                        examStatus.downloadData &&
                        examStatus.userShow ? (
                        <>
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            size="sm"
                            className="me-2"
                          />
                          Stop Live Exam
                        </>
                      ) : (
                        <>
                          {' '}
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            size="sm"
                            className="me-2"
                          />
                          Make Exam Live
                        </>
                      )}
                    </>
                  ),
                  onClick: () =>
                    examStatus.startAllowed &&
                      examStatus.downloadData &&
                      examStatus.userShow
                      ? makeExamNotLive()
                      : makeExamLive(),
                  variant:
                    examStatus.startAllowed &&
                      examStatus.downloadData &&
                      examStatus.userShow
                      ? 'danger'
                      : 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="me-2"
                      />
                      {examData.status == "completed" ? "Mark as Incomplete" : "Mark as Completed"}
                    </>
                  ),
                  onClick: markExamCompleted,
                  variant: examData.status == "completed" ? "danger" : "success",
                },

                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faGear}
                        size="sm"
                        className="me-2"
                      />
                      Check Live Exam
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/live-exam/' + examData._id, {
                      state: 1,
                    });
                  },
                  variant: 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faReplyAll}
                        size="sm"
                        className="me-2"
                      />
                      User Response
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/responses/' + examData._id);
                  },
                  variant: 'secondary',
                },
                // {
                //   value: (
                //     <>
                //       <FontAwesomeIcon
                //         icon={faCircleQuestion}
                //         size="sm"
                //         className="me-2"
                //       />
                //       Logs
                //     </>
                //   ),
                //   onClick: () => {
                //     navigate('/admin-dash/logs/' + examData._id);
                //   },
                //   variant: 'secondary',
                // },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faSquarePollVertical}
                        size="sm"
                        className="me-2"
                      />
                      Results
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/exam/' + examData._id + "/" + "result");
                  },
                  variant: 'secondary',
                },
              ]}
              size="sm"
            />
          </div>
        }
      />

      <CardBasic
        title={
          <div>
            <h5>
              Settings
            </h5>
          </div>
        }
        body={
          <>
            <div className="d-flex justify-content-end">
              <Toolbar
                values={[
                  {
                    value: <>
                      <FontAwesomeIcon icon={faUpload} size="sm" className="me-2" />
                      Upload File
                    </>,
                    onClick: () => { setShowCandidateDataModel(true) },
                    variant: "secondary"
                  },
                  {
                    value: <>
                      <CSVLink
                        data={csvData}
                        filename={"Invigilator.csv"}
                        className="text-white text-decoration-none"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFileExport} size="sm" className="me-2" />
                        Export Invigilator CSV
                      </CSVLink>
                    </>,
                    onClick: () => { },
                    variant: "secondary"
                  }
                ]}
                size="sm"
              />
            </div>
          </>
        }
        cardClass="mt-3"

      />
      <CardBasic
        cardClass="mt-3"
        title={
          <div className="d-flex justify-content-between align-items-center">
            <h4>Candidates</h4>
            <div className='d-flex align-items-center gap-3'>
              <Form.Group>
                <Form.Control type="text" placeholder='Search...' style={{ borderRadius: 50 }} onChange={(e: any) => setSearchText(e.target.value)} />
              </Form.Group>
            </div>
          </div>
        }
        body={
          <>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
              className="mb-3 mt-3"
            >
              <Tab eventKey="All" title="All Candidates">
                <UnmappedUsersTable
                  tableData={unmappedUsers}
                  reload={getAllUnmappedUser}
                />
                <div className="bg-white py-2">
                  <TablePagination
                    total={totalCount}
                    currentPage={currentPage}
                    perPage={perPage}
                    handlePageChange={(e: number) => {
                      setCurrentPage(e)
                    }}
                    setPerPage={(e: number) => { setPerPage(e) }}
                  />
                </div>
              </Tab>
              <Tab eventKey="Mapped" title="Mapped Candidates">
                <ExamUserTable
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  userData={userData}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalPages={totalPages}
                  reloadData={getAllMappedUser}
                  filterData={filterData}
                  setFilterData={setFilterData}
                />
              </Tab>
            </Tabs>
          </>
        }
      />


      <UploadCandidateData
        show={showCandidateDataModal}
        setShow={setShowCandidateDataModel}
        candidateData={userData}
      />
    </>
  );
}
