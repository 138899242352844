import { InvigilatorService } from "Services/invigilatorService";
import { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap"
import { toast } from "react-toastify";

interface IAddInvigilator {
    show: any,
    handleClose: any,
    reload: any
}

export default function AddInvigilator({ show, handleClose, reload }: IAddInvigilator) {

    const [data, setData] = useState<any>();

    const handleChangeValue = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const createInvigilator = async () => {
        await InvigilatorService.createInvigilator(data).then((res) => {
            if (res.status === 200) {
                toast.success("Invigilator Created");
                handleClose();
                reload();
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement="end" animation={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Invilator</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ height: "84vh" }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control name="mobile" onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                    </div>
                    <div>
                        <Button variant="secondary" className="w-100" onClick={createInvigilator}>Add</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}