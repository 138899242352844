import { faAddressBook, faCalendarDays, faComments, faLayerGroup, faShareFromSquare, faTicket, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { AdminService } from "Services/adminService";
import StatsCard from "components/Cards/Stats.card";
import moment from "moment";
import { useEffect, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker"


export default function StatsIndex() {

    const [startDate, setStartDate] = useState<any>(moment().subtract(1, "month").toDate());
    const [endDate, setEndDate] = useState<any>(new Date());

    const [statsData, setStatsData] = useState<any>();

    const getAllStats = async () => {
        await AdminService.getAllStatsData(startDate.toISOString(), endDate.toISOString()).then((res) => {
            if (res.status === 200) {
                setStatsData(res.data);
            }
        })
    }

    useEffect(() => {
        getAllStats();
    }, [startDate, endDate])

    return (
        <>
            <Card className="shadow-sm mt-4" style={{ border: "none" }}>
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-end">
                        <div className="d-flex gap-3">
                            <Form.Group>
                                <Form.Label className="fw-bold text-secondary">Start Date</Form.Label>
                                <DatePicker className="form-control" selected={startDate} onChange={(date: any) => setStartDate(date)} dateFormat={"dd/MM/yyyy"} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="fw-bold text-secondary">End Date</Form.Label>
                                <DatePicker className="form-control" selected={endDate} onChange={(date) => setEndDate(date)} dateFormat={"dd/MM/yyyy"} />
                            </Form.Group>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Row className="mt-4">
                <Col className="d-flex flex-wrap justify-content-between align-items-center gap-3 ">
                    {/* <StatsCard
                        title="Accept Marking"
                        value={statsData?.acceptedMarkings}
                        icon={faTicket}
                    />
                    <StatsCard
                        title="All Markings"
                        value={statsData?.allmarkings}
                        icon={faAddressBook}
                    /> */}
                    {/* <StatsCard
                        title="New Attempts"
                        value={statsData?.newAttempts}
                        icon={faShareFromSquare}
                    /> */}
                    <StatsCard
                        title="New Exams"
                        value={statsData?.newexams}
                        icon={faCalendarDays}
                    />
                    <StatsCard
                        title="New Logs"
                        value={statsData?.newlogs}
                        icon={faLayerGroup}
                    />
                    <StatsCard
                        title="New Mappings"
                        value={statsData?.newmappings}
                        icon={faUsers}
                    />
                    <StatsCard
                        title="New Questions"
                        value={statsData?.newquestions}
                        icon={faComments}
                    />
                    <StatsCard
                        title="New Students Response"
                        value={statsData?.newstudentResponses}
                        icon={faComments}
                    />
                    <StatsCard
                        title="New Users"
                        value={statsData?.newusers}
                        icon={faUsers}
                    />
                    <StatsCard
                        title="Invigilators"
                        value={statsData?.totalInvigilators}
                        icon={faUsers}
                    />
                </Col>
            </Row>
        </>
    )
}