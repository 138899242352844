import React, { useState } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { CSVDownload } from 'react-csv'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ExamServices } from '../../Services/examServices'
import CandidateDataDropzone, { CandidateZipDataDropzone } from '../Dropzone/CandidateData.dropzone'
import { useLoader } from '../Loader/Loader'
import SliderModal from './Slider.modal'

interface IUploadCandidateData {
    show: boolean
    setShow: any
    candidateData: any
}

const UploadCandidateData = (props: IUploadCandidateData) => {

    const {loading, setLoading} = useLoader();

    const [downloadData, setDownloadData] = useState<any[]>();


    const onDownloadClick = async () => {
        setDownloadData([
          {
            first_name: 'Ira',
            last_name: 'Killough',
            email: 'ikillough0@ucla.edu',
            // gender: 'Male',
            phone: '999077786',
            registration_number: 'xeigbt680',
            // dob: '02-04-1993',
            username: "login_username",
            // center_name: "name of center",
            // city: "city_name",
            // centerId: "center Id",
            password: "110622",
            image:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAJ8SURBVBgZBcHda5V1AADg5/d73/d42g66bF8ZTgbbxcpoKaNJXWQ3fUJJdRF5JxRF5EGEboIZ/QPiTeFNGF03GFiRUSZiUGxlSlnqtsZya6ustY9z3vPx9jyhfkRXacCEVBVARARABAA0ccvJfME7aWnAhOGDVX37STJiSgjEQIjEQAxICAgoWtz6rlr6ZEoqVdW3n3pC/xgJVn7izxliJImIJAiRoqDSTe8+eqeqKUgyYpkHDwEO0djg+jlunKW1jkBRIHB7mfJdtInaCCmbS1yZZPYiG6tkndz7HE+eYtc4INBoUGvQzMlJRcTA5hJXPyIG0kj3KKOH2THAQ0eZOc2Nc9Sb5HUaLWpEERH9Y7zwIY+9y9CzrM3y5VssXAD2vULfKPU6tSaNJjlRGxICsk56RnjgZZ44Rdcg0+8zfx44cJRiG7UGjRY1ooiAvy/z6ZtMn2ZjlayDR96mcg8XTrK+TKnC3meoNWm0yUlFxEgR2Vph4SuWvuX+w+x5lAPH+fgNi++9ZvHarzZ+uy4rp3avtw3mpNoQSArGj5NVWPiamQ/oHqHSZ3EluL2ybPT5I7YN3mfrx8/9fPEL6WYUQSzo2cuuMXpG2P0wa/9wZRLMTX9j6OCLyjfPC2de0jE7ac/ATvONIAUCWys0Nsk6WL1Jvc7vv4B89Q/l/kGeOgYgPXG3vB2kchRNtPjsGNkOFi5TbzL7PWdelXWWbf5wVufU6+pbyzbx31oiTUhODOvSf8e4O4cpVYgF23vZ0UdXHzEKMTN/7aqYRY1kw79/FeaWEt3t9qVQf1xXqd+EflVtNFFDjhw1NFm03dz6hrwVZElhZywuDa20n/4fCNbrcsCV4KMAAAAASUVORK5CYII=',
            membershipNumber: "123456789",
          },
        ]);
      };

    const handleUploadedFile = async(files: any) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("userupload", files[0]);
        await ExamServices.uploadCandidateData(props.candidateData._id, formData).then((res) => {
            if(res.status === 200) {
                toast.success("Upload Complete");
                props.setShow(false)
            }
        }).catch((err) => {
            toast.error("Something went wrong");
        })
        setLoading(false);
    }


    const handleUploadedZip = async(files: any) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("userupload", files[0]);
        await ExamServices.uploadZipUserData(props.candidateData._id, formData).then((res) => {
            if(res.status === 200) {
                toast.success("Upload Complete");
                props.setShow(false)
            }
        }).catch((err) => {
            toast.error("Something went wrong");
        })
        setLoading(false);
    }

    return (
        <>
        <SliderModal
            show={props.show}
            setShow={props.setShow}
            body={
                <>
                 <Tabs
                    defaultActiveKey="csv-upload"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="csv-upload" title="Csv Upload">
                        <div className="d-flex justify-content-end align-items-center mb-2">
                            <Button variant='primary' size="sm" onClick={onDownloadClick}>Download Sample CSV File</Button>
                        </div>
                        <CandidateDataDropzone handleUploadedFile={handleUploadedFile} />
                    </Tab>
                    <Tab eventKey="zip-upload" title="Zip Upload">
                        <div className="d-flex justify-content-end align-items-center mb-2">
                        <a href="https://dicom-data.s3.ap-south-1.amazonaws.com/Archive.zip" target="_blank" download>Download Sample Zip File</a>
                        </div>
                        <CandidateZipDataDropzone handleUploadedFile={handleUploadedZip} />
                    </Tab>
                </Tabs>
                </>

            }
            title="Upload File"
        />
        {downloadData && downloadData.length > 0 ? (
            <CSVDownload data={downloadData} />
          ) : (
            ' '
          )}
          </>
    )
}

export default UploadCandidateData