import { InvigilatorService } from "Services/invigilatorService";
import AddInvigilator from "components/Modals/AddInvigilator.modal";
import InvigilatorTable from "components/Tables/Ingilator.table";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";

export default function Invigilator() {

    const [showAddInvigilator, setShowAddInvigilator] = useState<boolean>(false);
    const [invigilator, setInvigilator] = useState<any>();


    const getAllInvigilator = async () => {
        await InvigilatorService.getAllInvigilator().then((res) => {
            if (res.status === 200) {
                setInvigilator(res.data.data.invigilator)
            }
        })
    }


    useEffect(() => {
        getAllInvigilator();
    }, [])

    return (
        <>
            <div className="mt-4">
                <div className="d-flex justify-content-between align-items-center">
                    <h5>Invigilator</h5>
                    <Button size="sm" variant="secondary" onClick={() => setShowAddInvigilator(true)}>Add Invigilator</Button>
                </div>
                <div className="mt-3">
                    <Card>
                        <Card.Body>
                            <InvigilatorTable tableData={invigilator} reload={getAllInvigilator} />
                        </Card.Body>
                    </Card>
                </div>
            </div>


            <AddInvigilator
                show={showAddInvigilator}
                handleClose={() => setShowAddInvigilator(false)}
                reload={getAllInvigilator}
            />
        </>
    )
}