import { ISection } from 'components/Sections';
import Tags from 'components/Tags';
import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap'
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ExamServices } from 'Services/examServices';



interface ICreateExamModal {
    show: boolean;
    setShow: any;
    reload: any
}


export default function DCMCreateExamModal(props: ICreateExamModal) {
    const [allLanguage, setAllLanguage] = useState<any[]>([])


    const [examData, setExamData] = useState({
        "examName": "",
        "subject": "",
        "tags": [] as string[],
    })


    const createExam = async () => {
        let createExamData: any = examData;

        await ExamServices.createExam(createExamData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Exam created successfully.")
                    props.setShow(false);
                    props.reload();
                }
                else (
                    console.log(res)
                )
            })
            .catch((err) => {
                toast.error(err);
            })
    }

    const handelChange = (e: any) => {
        setExamData({ ...examData, [e.target.name]: e.target.value })
    }

    // const handleSectionsChange = (sectionUpdatedData: ISection, index: number) => {
    //     const sectionData = section;
    //     sectionData[index] = sectionUpdatedData;
    //     setSection(sectionData);
    // }

    const getAllLanguages = async () => {
        await ExamServices.getAllLanguages().then((res) => {
            if (res.status == 200) {
                // console.log(res.data)
                setAllLanguage(res.data.data.map((data: any) => {
                    return {
                        value: data,
                        label: data
                    }
                }));
            }
        }).catch((err) => {
            console.log(err.response);
        })
    }

    // const options = allLanguage.map((data: any, index: any) => ({
    //     "value" : data,
    //     "label" : data
    // }))


    useEffect(() => {
        getAllLanguages();
    }, [])


    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props.setShow(false)}
                show={props.show}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Exam
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Exam Name</Form.Label>
                            <Form.Control type="text" placeholder="Exam name" name="examName" onChange={handelChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control type="text" placeholder="Exam Subject" name="subject" onChange={handelChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Choose Languages</Form.Label>
                            <Select
                                // defaultValue={[options[0]]}
                                isMulti
                                name="language"
                                options={allLanguage}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Start Time of Exam</Form.Label>
                                <Form.Control type="datetime-local" placeholder="Enter Subject" name="startTime" onChange={handelChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>End Time of Exam</Form.Label>
                                <Form.Control type="datetime-local" placeholder="Enter Subject" name="endTime" onChange={handelChange} />
                            </Form.Group> */}
                        <hr />
                        <Tags
                            tags={examData.tags}
                            setTags={(value: any) => { setExamData({ ...examData, ['tags']: value }) }}
                        />
                        {/* <hr /> */}

                        {/* <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    Sections
                                </div>
                                <Button onClick={() => setCount(count + 1)} size="sm">Add Section</Button>
                            </div>
                            {[...Array(count)].map((_, i) => <div className="mt-2 pb-4">
                                <Sections
                                    section={section[i]}
                                    handleSections={handleSectionsChange}
                                    count={count}
                                    setCount={setCount}
                                />
                            </div>)} */}
                        <hr />
                        <Button variant="primary" type="button" onClick={createExam}>
                            Create Exam
                        </Button>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}