import { faBarChart, faBinoculars, faBook, faBookMedical, faBullseye, faChartLine, faCircleQuestion, faDashboard, faPerson, faReplyAll } from "@fortawesome/free-solid-svg-icons";
import IRouter from "../interfaces/IRouter";
import Dashboard from "../view/private/Dashboard/Dashboard";
import Exam from "../view/private/Exam/Exam";
import SelectedExam from "../view/private/Exam/SelectedExam/SelectedExam";
import LogsIndex from "../view/private/Logs/Logs.index";
import LiveExam from "../view/private/LiveExam/LiveExam.index";
import AddQuestion from "../view/private/Questions/AddQuestion";
import ExamQuestions from "../view/private/Questions/ExamQuestions/ExamQuestions";
import ExamQuestionIndex from "../view/private/Questions/ExamQuestions/ExamQuestions.index";
import Questions from "../view/private/Questions/Question.index";
import QuestionDetails from "../view/private/Questions/QuestionDetails";
import StudentIndex from "../view/private/Students/Students.index";
// import Result from "../view/private/Exam/SelectedExam/Result";
import StudentResult from "../view/private/Exam/SelectedExam/StudentResult";
import ResponsesIndex from "../view/private/Responses/Responses.index";
import Response from "../view/private/Responses/Response/Response";
import ResultIndex from "view/private/Results/Result.index";
import InvigilatorIndex from "view/private/Invigilator/Invigilator.index";
import InvigilatorDetails from "view/private/Invigilator/InvigilatorDetails";
import StatsIndex from "view/private/Stats/Stats.index";

export const baseUrl = "/admin-dash"

const adminRoutes: IRouter[] = [
    {
        path: "exam",
        navbarShow: true,
        element: Exam,
        name: "Exam",
        icon: faBook
    },
    {
        path: "invigilator",
        navbarShow: true,
        element: InvigilatorIndex,
        name: "Invigilator",
        icon: faBinoculars
    },
    {
        path: "stats",
        navbarShow: true,
        element: StatsIndex,
        name: "Statistics",
        icon: faChartLine
    },
    {
        path: "invigilator/:invigilatorId",
        navbarShow: false,
        element: InvigilatorDetails,
        name: "InvigilatorDetails"
    },
    {
        path: "live-exam/:examId",
        navbarShow: false,
        element: LiveExam,
        name: "Exam",
        icon: faBook
    },
    {
        path: "exam/:examId",
        navbarShow: false,
        element: SelectedExam,
        name: "Selected-Exam"
    },
    {
        path: "exam/:examId/result",
        navbarShow: false,
        element: ResultIndex,
        name: "Exam-result"
    },
    {
        path: "exam/:examId/result/:userId",
        navbarShow: false,
        element: StudentResult,
        name: "Result-Selected-Student"
    },
    {
        path: "exam/getExamQuestions/:examId",
        navbarShow: false,
        element: ExamQuestionIndex,
        name: "Exam Questions"
    },
    {
        path: "exam/:examId/questions/:questionId",
        navbarShow: false,
        element: QuestionDetails,
        name: "add question details",
        icon: faCircleQuestion
    },
    {
        path: "exam/addQuestions/:Id",
        navbarShow: false,
        element: AddQuestion,
        name: "add question by id",
        icon: faCircleQuestion
    },
    {
        path: "logs/:examId",
        navbarShow: true,
        element: LogsIndex,
        name: "Logs",
        icon: faCircleQuestion
    },
    {
        path: "responses/:examId",
        navbarShow: false,
        element: ResponsesIndex,
        name: "Responses",
        icon: faReplyAll
    },
    {
        path: "responses/:examId/:mappingId",
        navbarShow: false,
        element: Response,
        name: "Responses",
    }
]

export default adminRoutes