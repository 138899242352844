import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { ExamServices } from '../../../../Services/examServices';
import SelectedExamCard from './SelectedExamCard';
import { Container } from 'react-bootstrap';

const SelectedExam = () => {
    const params: any = useParams();

    const [examById, setExamById] = useState<any>({});




    const getExamById = async () => {
        await ExamServices.getExamById(params.examId).then((res) => {
            if (res.status === 200) {
                setExamById(res.data.data);
            }
        }).catch((err) => {
            toast.error(err);
        })
    }



    useEffect(() => {
        getExamById();
    }, []);


    return (
        <SelectedExamCard examData={examById} reloadData={getExamById} />
    )
}

export default SelectedExam