import moment from 'moment';
import { Table } from 'react-bootstrap';

interface IUsersTable {
  tableData: any;
  tableRows?: any;
  pageNumber?: number;
  pageCount?: number;
}

const UsersTable = ({
  tableData,
  tableRows,
  pageNumber,
  pageCount,
}: IUsersTable) => {
  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
  };

  return (
    <Table striped borderless className="xrg-table" size="lg" responsive>
      <thead>
        <tr>
          <th>Sr. No.</th>
          <th className='text-nowrap'>Username/Reg. No.</th>
          {/* <th className='w-auto'>DOB</th> */}
          <th className='text-center'>Invigilator</th>
          <th className='text-center'>Student Submission</th>
          <th className='text-center'>Invigilator Submission</th>
          <th className='text-center'>Completed</th>
          <th> Attempted / Approved / Attempted but Unapproved / Review</th>
          <th>Certificate</th>
          <th className='text-nowrap'>Lock/Un-Lock</th>
        </tr>
      </thead>
      <tbody>{tableRows && tableRows}</tbody>
    </Table>
  );
};

export default UsersTable;
