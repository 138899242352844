import { ExamServices } from 'Services/examServices';
import React, { useEffect, useState } from 'react';
import { ListGroup, Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import EditAccessModal from './Modals/EditAccessModal';
import { InvigilatorService } from 'Services/invigilatorService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface Exam {
    students?: string[];
    _id: string;
    examId: string;
}

interface AllottedExamsProps {
    allottedExam: Exam;
    reload: any;
}

const AllottedExam: React.FC<AllottedExamsProps> = ({ allottedExam, reload }) => {

    const [exam, setExam] = useState<any>({});
    const [examStudents, setExamStudents] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);
    const { invigilatorId } = useParams();

    async function getAllExams() {
        await ExamServices.getAllExams()
            .then((res) => {
                if (res.status === 200) {
                    let filteredExam = res.data.data?.examData?.filter((exam: any) => { return exam._id === allottedExam.examId })
                    setExam(filteredExam[0]);
                    getExamStudents(filteredExam[0]?._id);
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    async function getExamStudents(examId: string) {
        await ExamServices.getAllUsers(examId)
            .then((res) => {
                if (res.status === 200) {
                    let filteredStudents = [];
                    if (res.data.data.users.length > 0) {
                        filteredStudents = res.data.data.users.filter((item: any) => { return allottedExam.students?.includes(item?.student._id) });
                        // console.log(filteredStudents);
                    }
                    setExamStudents(filteredStudents);
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    async function handleDelete() {
        await InvigilatorService.deleteAllotedExam(allottedExam.examId,invigilatorId)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.data?.message);
                    reload();
                }
            })
            .catch(e => {
                console.log(e);
                toast.error(e.response.data.message);
            })
    }

    useEffect(() => {
        if (allottedExam.examId !== '') {
            getAllExams();
        }
    }, [allottedExam.examId]);


    return (
        <div className='mb-3'>
            <Card>
                <Card.Body>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span>
                            <strong>Exam:</strong> {exam?.examName}
                        </span>
                        <div className="d-flex gap-2">
                            <Button size="sm" variant="outline-danger" onClick={() => setShow(true)}>Edit</Button>
                            <Button size="sm" variant="danger" onClick={() => handleDelete()}>Delete</Button>
                        </div>
                    </div>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Students</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    {
                                        examStudents &&
                                            examStudents.length > 0 ?
                                            examStudents?.map((student: any, index: number) => (
                                                <Row key={student?.student._id}>
                                                    <span>{index + 1}.{student?.student?.name + ' ' + student.student?.last_name + ' (' + student.student?.username + ' )'}</span>
                                                </Row>
                                            )) :
                                            <Row>No students allotted</Row>
                                    }
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>
            </Card>
            <EditAccessModal
                show={show}
                setShow={setShow}
                data={allottedExam}
                reload={reload}
            />
        </div>
    );
};

export default AllottedExam;
