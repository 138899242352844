import makeRequest, { makeParams } from "api/makeRequest";
import { RequstMethods } from "api/requestMethode";
import url from "api/urls";

export class InvigilatorService {
    static async getAllInvigilator() {
        return await makeRequest(url.invigilator.getAllInvigilator, RequstMethods.GET)
    }

    static async createInvigilator(payload: any) {
        return await makeRequest(url.invigilator.createInvigilator, RequstMethods.POST, payload)
    }

    static async deleteInvigilator(invigilatorId: any) {
        return await makeRequest(url.invigilator.deleteInvigilator + "/" + invigilatorId, RequstMethods.DELETE)
    }

    static async updateInvigilator(invigilatorId: any, payload: any) {
        return await makeRequest(url.invigilator.updateInvigilator + "/" + invigilatorId, RequstMethods.PUT, payload)
    }

    static async resetPassword(invigilatorId: any) {
        return await makeRequest(url.invigilator.resetPassword + "/" + invigilatorId, RequstMethods.PUT)
    }

    static async getInvigilator(invigilatorId: any) {
        return await makeRequest(url.invigilator.getInvigilatorById + "/" + invigilatorId, RequstMethods.GET);
    }

    static async allotExam(invigilatorId: any, payload: any) {
        return await makeRequest(url.invigilator.allotExam + "/" + invigilatorId, RequstMethods.PUT, payload);
    }

    static async updateAllotedExam(invigilatorId: any, payload: any) {
        return await makeRequest(url.invigilator.editAllotedExam + "/" + invigilatorId, RequstMethods.PUT, payload);
    }

    static async changeStatus(invigilatorId: any) {
        return await makeRequest(url.invigilator.changeStatus + "/" + invigilatorId, RequstMethods.PUT);
    }

    static async deleteAllotedExam(examId:string, invigilatorId: any) {
        let params = makeParams([
            {
                index:"invigilatorId",
                value:invigilatorId
            },
            {
                index:'examId',
                value:examId
            }
        ]);
        return await makeRequest(url.invigilator.deleteAllotedExam+params, RequstMethods.PUT)
    }
}

