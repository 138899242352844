import { AdminService } from 'Services/adminService';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IUnmappedUsers {
  tableData: any,
  reload: any
}

const UnmappedUsersTable = ({
  tableData,
  reload
}: IUnmappedUsers) => {

  const params = useParams();


  const createMappeduser = async (userId: any) => {
    await AdminService.createMappedUser(params.examId, userId).then((res) => {
      if (res.status === 200) {
        toast.success("User Mapped Successfully");
        reload();
      }
    }).catch(err => {
      toast.error(err.response.data)
    })
  }


  return (
    <Table striped borderless className="xrg-table">
      <thead>
        <tr>
          <th>Sr. No.</th>
          <th>Name</th>
          <th>Email</th>
          <th>DOB</th>
          <th>Reg. No.</th>
          <th>Gender</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td className="xrg-text-12">{data?.name}{" "}{data?.last_name}</td>
              <td className="xrg-text-12">{data?.email}</td>
              <td className="xrg-text-10">{moment(data?.dob).format("DD MMM YYYY")}</td>
              <td className="xrg-text-12">{data?.registrationNumber}</td>
              <td className="xrg-text-12">{data?.gender?.toUpperCase()}</td>
              {!data?.isMapped ?
                <td><Button size="sm" variant='success' onClick={() => createMappeduser(data._id)}>Map this User</Button></td> :
                <td><span className="text-primary fw-normal">Already Mapped</span></td>
              }
            </tr>
          )
        }) : "No users found"}
      </tbody>
    </Table>
  );
};

export default UnmappedUsersTable;
