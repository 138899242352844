import { faCheckCircle, faEdit, faEllipsisVertical, faExchange, faEye, faKey, faTrash, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvigilatorService } from "Services/invigilatorService";
import CustomToggle from "components/Menu/CustomMenu";
import EditInvigilator from "components/Modals/EditInvigilator.modal";
import ShowPasswordModal from "components/Modals/ShowPassword.modal";
import { useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


interface IAddInvigilator {
    tableData: any,
    reload: any
}

export default function InvigilatorTable({ tableData, reload }: IAddInvigilator) {

    const [invigilatorIndex, setInvigilatorIndex] = useState<number>(-1)
    const [password, setPassword] = useState<any>(undefined);
    const navigate = useNavigate();

    const deleteInvigilator = async (id: any) => {
        await InvigilatorService.deleteInvigilator(id).then((res) => {
            if (res.status === 200) {
                reload();
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const resetPassword = async (id: any) => {
        await InvigilatorService.resetPassword(id).then((res) => {
            if (res.status === 200) {
                setPassword(res.data.data.password)
            }
        }).catch(e => {
            console.log(e);
        })
    }

    async function changeStatus(invigilatorId: string) {
        await InvigilatorService.changeStatus(invigilatorId).then((res) => {
            if (res.status === 200) {
                reload();
            }
        }).catch(e => {
            console.log(e);
        })
    }

    return (
        <>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Mobile</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData && tableData.length > 0 && tableData.map((data: any, index: number) => {
                        return (
                            <tr>
                                <td>
                                    {index + 1}
                                </td>
                                <td>{data?.name}</td>
                                <td>{data?.email}</td>
                                <td><Badge bg="secondary">{data?.userType}</Badge></td>
                                <td>{data?.mobile}</td>
                                <td>{data?.status === "in-active" ? <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" /> : <FontAwesomeIcon icon={faCheckCircle} className="text-success" />}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="dropdown-custom-components"
                                        >
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                    onClick={() => resetPassword(data._id)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faKey}
                                                    className="text-info"
                                                />
                                                <span className="fw-bold text-secondary fs-12 ms-2">
                                                    Reset Password
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setInvigilatorIndex(index)}>
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="text-info"
                                                />
                                                <span className="fw-bold text-secondary fs-12 ms-2">
                                                    Edit
                                                </span>
                                            </Dropdown.Item>
                                                <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => navigate('./' + data?._id)}>
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    className="text-info"
                                                />
                                                <span className="fw-bold text-secondary fs-12 ms-2">
                                                    View Access
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => changeStatus(data?._id)}>
                                                <FontAwesomeIcon
                                                    icon={faExchange}
                                                    className="text-secondary"
                                                />
                                                <span className="fw-bold text-secondary fs-12 ms-2">
                                                    {data?.status === "in-active" ? "Activate" : "Deactivate"}
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => deleteInvigilator(data._id)}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="text-danger"
                                                />
                                                <span className="fw-bold text-danger fs-12 ms-2">
                                                    Delete
                                                </span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>

                        )
                    })}
                </tbody>
            </Table>

            <EditInvigilator
                show={invigilatorIndex >= 0 ? true : false}
                handleClose={() => setInvigilatorIndex(-1)}
                reload={reload}
                invigilatorData={tableData && tableData[invigilatorIndex]}
            />

            <ShowPasswordModal
                show={password}
                handleClose={() => setPassword(undefined)}
            />
        </>
    )
}