import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExamServices } from '../../Services/examServices';
import Toolbar from '../Toolbar/Toolbar';

interface IOptionValueShow {
    value: any,
    reload: any
}
export default function OptionValueShow(props: IOptionValueShow) {

    const {questionId}: any = useParams();


    const deleteQuestion = async() => {
        await ExamServices.deleteOptionById(questionId, props.value._id).then((res) => {
            if(res.status === 200) {
                toast.success("Question deleted successfully");
                props.reload();
            }
        }).catch((err) => {
            toast.error(err + "Unable to delete");
        })
    }


    return (
        <>
            <div className='d-flex justify-content-between'>
                <div>
                    <h6 className="text-secondary fw-bold">{props.value.name.toUpperCase()}</h6>
                    <div>
                        Language: <b>{props.value.language}</b>
                    </div>
                    <div>
                        Type: <b>{props.value.fileUploadAllowed? 'File' :props.value.data ? "IMAGE/DICOM": props.value.value && props.value.choice ? "CHOICE WITH TEXT" : props.value.choice ? "CHOICE" : props.value.boolean ? "Boolean" : "TEXT"}</b>
                    </div>
                </div>
                <div>
                    <Button variant='danger' size="sm" onClick={deleteQuestion}>
                        <FontAwesomeIcon icon={faClose} /> Delete
                    </Button>
                </div>
            </div>

        </>
    )
}