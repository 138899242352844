const url = {
  login: '/auth/login',
  stats: "/admin/stats/get",
  exam: {
    getAllExams: 'exam/getAllExam',
    crateExam: 'exam/createExam',
    deleteOneExam: 'exam/deleteOneExam',
    updateExam: '/exam/updateExam',
    getExamById: '/exam/getExamById',
    updateExamStatus: '/exam/updateExamStatus',
    uploadCandidateData: '/examMapping/uploadUserCsvData',
    uploadZipUserData: "/examMapping/uploadZipUserData",
    getAllMappedUser: '/examMapping/getAllMappedUser',
    deleteMappingForExam: '/examMapping/deleteExamMappingForExam',
    getExamLanguage: '/exam/getExamLanguages',
    setExamDateAndTime: '/exam/updateExamTime',
    setuserAllowedTillTime: '/exam/updateUserTillTime',
    setUserAllowedTime: '/exam/updateUserAllowedExamTime',
    markExamCompleted: '/exam/markAsCompleted',
    getAllUsers: '/exam/getAllExamStudents',
    getExamLayout: "/exam/getExamLayout",
    getExamInvigilatorStats: "/admin/exam/invigilator/stats"
  },
  admin: {
    getQuestionsByExamId: 'admin/getQuestionsByExamId',
    getQuestionById: 'admin/getQuestionById',
    addQuestionToExam: 'admin/addQuestionToExam',
    addAnswerToQuestion: 'admin/addAnswerToQuestion',
    addQuestionToQuestionById: 'admin/addQuestionToQuestion',
    makeExamive: '/admin/makeExamLive',
    getExamsections: '/admin/getExamSections',
    addOptionToQuestion: '/admin/addOptionToQuestion',
    deleteQuestionFromQuestionId: '/admin/deleteQuestionFromQuestionById',
    userAllowedStartTime: '/exam/userAllowedStartTime',
    getQuestionDataByExamIdAndSectionId:
      '/exam/getExamQuestionsByExamIdSectionId',
    deleteQuestionById: '/admin/deleteQuestionById',
    deleteOptionById: '/admin/deleteOptionById',
    deleteQuestionFromQuestion: '/admin/deleteQuestionFromQuestionById',
    getAllResponsesByExamId: '/admin/getAllResponsesByExamId',
    deleteStatementById: '/admin/deleteStatementById',
    getUserResponseByMappingId: '/admin/getUserResponseByMappingId',
    getAllCompletedUsersForExam: '/admin/getAllCompletedUsersForExam',
    getS3Link: "/admin/getS3Link",
    getDecriptionKeys: "/admin/getDecriptionKeys",
    setTimeMapping: '/admin/addChangeMappingTime',
    toggleUserActive: '/admin/toggleUserActive',
    resetMappingTime: "/admin/resetMappingTime",
    deleteQuestionDicomByQuestionDataId: "/admin/deleteQuestionDicomByQuestionDataId",
    deleteQuestionImageByQuestionDataId: "/admin/deleteQuestionImageByQuestionDataId",
    duplicateQuestion: "/admin/duplicateQuestion",
    questionMoveQuestionId: "/admin/question/move",
    user_logs: "/admin/user/logs",
    getAllUnmappedUsers: "/admin/user/unmapped",
    createMappedUser: "/admin/user/map",
    toggleUpdate: "/admin/toggleUpdate",
    getStats: "/admin/stats/get",
  },
  user: {
    getAllUsers: '/admin/getAllUser',
    resetPasswordForUser: '/user/resetPasswordForUser',
    deleteStudentMapping: '/examMapping/deleteStudentMapping',
    disableStudentMapping: '/examMapping/disableStudentMapping',
    toggleStudentMapping: '/examMapping/toggleStudentMapping',
    unMarkCompletedMapping: '/examMapping/unMarkCompletedMapping',
    genrateCertificate: '/certificate/generate'
  },
  logs: {
    getAllLogs: '/log/getAllLogs',
    getAllLogsByMappingId: "/log/getAllLogsByMappingId",
  },
  pulic: {
    getQuestionType: '/public/getQuestionTypes',
    getOptionType: '/public/getOptionTypes',
    getQuestionDataTypes: '/public/getQuestionDataTypes',
    getAllLanguages: '/public/getAllLanguages',
  },
  result: {
    getResultResponse: "/result/getResponses",
    getEncryptedOptionById: "/result/getencryptedOptionsById",
    createUserResultCSVByExamId: "/result/createUserResultCSVByExamId",
    ans_mark: "/answer/result/mark",
    getQuestionAnswersByExamId: "/answer/result/getQuestionAnswers",
    generateResult: "/answer/result/create",
    createUserResult: "/result/createUserResult",
    downloadExistingResultZip: "/result/downloadExistingResultZip",
  },

  invigilator: {
    getAllInvigilator: "/invigilator/getInvigilator",
    createInvigilator: "/invigilator/createInvigilator",
    deleteInvigilator: "/invigilator/deleteInvigilator",
    updateInvigilator: "/invigilator/updateInvigilator",
    resetPassword: "/invigilator/password/reset",
    getInvigilatorById: "/invigilator/getInvigilatorById",
    allotExam: "/admin/add/allottedExams",
    editAllotedExam: '/admin/update/allottedExams',
    changeStatus: '/invigilator/status',
    deleteAllotedExam: '/admin/exam/remove'
  }
};

export default url;
