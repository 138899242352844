import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserType } from 'interfaces/LiveExamUser';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import moment from 'moment';
import PromptModal from './Modals/Prompt.modal';
import prettyMs from 'pretty-ms';
import {
  faCircle,
  faSignal,
  faLinkSlash,
  faStopwatch20,
  faUnlock,
  faLock,
  faCheck,
  faRefresh,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BooleanIcon from './Badge/BooleanIcon';
import { ExamServices } from 'Services/examServices';
import { useParams } from 'react-router-dom';

interface ContextType {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  userId?: string;
  onSubmit?: (time: number) => void;
  SetUserId?: (userid?: string) => void;
  setMappingId?: React.Dispatch<React.SetStateAction<string>>;
  onActiveClick?: (id: string) => void;
  handleResetTimeChange?:any
}

const Context = createContext<ContextType>({});

interface Proptype {
  users: UserType[];
  onTimeSet?: (time: number, userId?: string, mappingid?: string) => void;
  handleResetTimeChange: any
}

interface IUsersTable {
  tableRows?: any;
  pageNumber?: number;
  pageCount?: number;
  setOpen?: any;
  open?: boolean;
  onSubmit?: (value: number) => void;
}

export interface ITableRow {
  data: any;
}

const TableRows = ({ data }: ITableRow) => {
  const { setOpen, SetUserId, setMappingId, onActiveClick, handleResetTimeChange } =
    useContext(Context);

  const handleOpen = (id: string, mappingid: string) => {
    SetUserId?.(id);
    setOpen?.(true);
    setMappingId?.(mappingid);
  };


  const [resetTimeConfirmation, setResetTimeConfirmation] = useState<any>(undefined);
  const [examTime, setExamTime] = useState(0);


  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
  };




  return (
    <>

        <PromptModal
          show={resetTimeConfirmation ? true : false}
          setShow={setResetTimeConfirmation}
          body={
            <>
              <div>
                  <span className='text-danger'> 
                    Warning You are about to reset user time
                  </span>
                  <span className='ms-2'>
                    Make Sure user is offline before resetting time, value should be less than or equal to total time available in exam
                  </span>
              </div>
              <div>
                <Form.Label>Time in Minutes</Form.Label>
                <Form.Control type="number" onChange={(e:any)=>setExamTime(e.target.value)} placeholder="Time in mins"/>
              </div>
              <div>
                <Button 
                variant="danger"
                onClick={() => {
                  handleResetTimeChange(resetTimeConfirmation, examTime)
                  setResetTimeConfirmation(undefined)
                }
                }>
                  Update User Time
                  </Button>
              </div>
            </>
          }
          submitButton = {
            () => {

            }
          }
        />

      {data.map((data: any, index: number) => {
        return (
          <tr key={index}>
            <td className="text-secondary">{index + 1}</td>
            <td className="text-primary xrg-text-12">{data.student && data.student.registrationNumber}</td>
            <td className="text-secondary xrg-text-12">{data.student && data.student.city}</td>
            <td className="text-secondary xrg-text-12">{data.student && data.student.centerId}</td>
            <td className="text-secondary xrg-text-12">{data.student && data.student.username}</td>
            <td className="xrg-text-10">
              {moment(data.student && data.student.dob).format('DD-MM-YYYY')}
            </td>
            <td className="xrg-text-12">
              {data.student && data.student.name + ' ' + data.student.last_name}
            </td>

            <td className="text-muted xrg-text-10 text-monospace">
              {data.student && data.student.email}
            </td>
            <td className="text-center">
              <BooleanIcon
                values={{ true: faCheck, false: faXmark }}
                status={data ? data.completionStatus : "--"}
                size="sm"
              />
            </td>
            <td className="text-center">
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => onActiveClick?.(data._id)}
              >
                <BooleanIcon
                  values={{ true: faUnlock, false: faLock }}
                  status={data ? data.active : "--"}
                  size="sm"
                />
              </div>
            </td>
            <td className="text-center">
              <BooleanIcon
                values={{ true: faSignal, false: faLinkSlash }}
                status={data ? data.online : "--"}
                size="sm"
              />
            </td>
            {/* <td className="fw-bold text-secondary xrg-text-10">
              {data.remainingTime < 0
                ? '---'
                : prettyMs(Number(data.remainingTime) * 1000)}
            </td> */}
            {/* <td>
              <div className="d-flex justify-content-start align-items-center">
              <div>
                <FontAwesomeIcon
                  icon={faStopwatch20}
                  className="text-muted"
                  onClick={() => handleOpen(data.student._id, data._id)}
                />
              </div>
              <div className="ms-2">
                <FontAwesomeIcon icon={faRefresh} className="text-primary cursor-pointer" onClick={() => {
                  setResetTimeConfirmation(data._id)
                }
                } />
              </div>
              </div>
            </td> */}
          </tr>
        );
      })}
    </>
  );
};

const UsersTable = ({ tableRows }: IUsersTable) => {
  const [time, setTime] = useState(0);
  const { onSubmit, open, setOpen } = useContext(Context);
  return (
    <>
      <PromptModal
        body={
          <div>
            <InputGroup className="mb-3">
              <InputGroup.Text
                onClick={() => setTime((time) => --time)}
              >
                -
              </InputGroup.Text>
              <Form.Control
                value={time}
                type={'number'}
                onChange={(e: any) => setTime(e.target.value)}
              />
              <InputGroup.Text>Minutes</InputGroup.Text>
              <InputGroup.Text
                onClick={() => setTime((time) => ++time)}
              >
                +
              </InputGroup.Text>
            </InputGroup>
          </div>
        }
        show={open as boolean}
        setShow={setOpen}
        submitButton={
          <Button
            variant="success"
            className="mt-1 ms-2"
            onClick={() => onSubmit?.(time)}
          >
            Set
          </Button>
        }
      />

       

      <Table
        striped
        hover
        borderless
        className="xrg-table"
        size="sm"
        responsive="md"
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Reg. No.</th>
            <th>City</th>
            <th>C. Id</th>
            <th>Username</th>
            <th>DOB</th>
            <th>Name</th>
            <th>Email</th>
            <th className="text-center">Completed</th>
            <th className="text-center">Lock/Un-lock</th>
            <th className="text-center">Online</th>
            {/* <th>Time Rem.</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>{tableRows && tableRows}</tbody>
      </Table>
    </>
  );
};

const UserList: React.FC<Proptype> = ({ users, onTimeSet, handleResetTimeChange }) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [mappingId, setMappingId] = useState('');
  const [userData, setUseraData] = useState(users);
  const onSubmit = (time: number) => {
    setOpen(false);
    onTimeSet?.(time, userId, mappingId);
  };
  const SetUserId = (userid?: string) => {
    setUserId(userid);
  };
  const onActiveClick = async (id: string) => {
    setUseraData((users) =>
      users.map((user) => {
        if (user._id === id) {
          return { ...user, active: !user.active };
        }
        return user;
      }),
    );
    await ExamServices.toggleUserActive(id);
  };


  useEffect(() => {
    setUseraData(users);
  }, [users]);

  return (
    <Context.Provider
      value={{
        open,
        setOpen,
        userId,
        onSubmit,
        SetUserId,
        setMappingId,
        onActiveClick,
        handleResetTimeChange
      }}
    >
      <UsersTable tableRows={<TableRows data={userData} />} />
    </Context.Provider>
  );
};

export default UserList;
